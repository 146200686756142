.filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem;
}

.filter-input {
  font-size: 16px;
  font-weight: normal;
  padding: 6px 16px;
}
.filter-input:hover {
  cursor: pointer;
}

.filter-input-unmarked {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  border: 2px solid black !important;
  border-radius: 5rem;
  color: rgb(0, 0, 0);
  border: none;
}
.filter-input-unmarked:hover {
  background-color: rgb(230, 230, 230);
}

.filter-input-marked {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border: 2px solid black !important;
  color: white;
  border-radius: 5rem;
}
input[type="checkbox"] {
  display: none;
}

.clear-filter {
  margin: 1rem;
}

.clear-filter-btn {
  background-color: #e6e6e6;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(0, 0, 0);
  border: none;
}
.clear-filter-btn:hover {
  background-color: rgb(230, 230, 230);
}
