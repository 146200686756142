html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  font-family: Roboto, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

strong,
b {
  font-weight: 500;
}

hr {
  height: 1px;
  margin: 0;
  border: none;
  flex-shrink: 0;
  background-color: var(--border-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  color: inherit;
}

blockquote {
  border-left: 2px solid var(--border-color);
  margin-left: 8px;
  margin-right: 0;
  padding-left: 8px;
  color: var(--text-secondary);
  font-style: italic;
}

@media (max-width: 576px) {
  .page__content {
    padding: 10px !important;
  }
}
