/*-----------------*/
/* Utility Classes */
/*-----------------*/
:root {
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
}

/* .app__content-container {
  min-height: 100vh;
} */

/* .page__container {
  min-height: auto !important;
} */

strong {
  font-weight: bold;
}
/* REACT DATES */
.DateInput {
  width: 100%;
}

.DateInput:hover:before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.DateInput:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.DateInput_input {
  font: inherit;
  width: 100%;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 3px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
}

.DateInput_input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.SingleDatePicker {
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.1875em;
  align-items: center;
}

.SingleDatePickerInput__withBorder {
  border-radius: 0px;
  border: none;
}

.SingleDatePicker_picker {
  z-index: 10;
}

/* REACT-DATES CALENDAR */
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.CalendarDay__highlighted_calendar {
  background: #fff;
  color: #484848;
}

.CalendarDay__highlighted_calendar:hover {
  background: #ededed;
  color: #484848;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.header__logo-img {
  height: 50px;
  width: 50px;
}

@media (max-width: 667px) {
  .header__logo-img {
    height: 30px;
    width: 30px;
  }
}

/* Buttons */

button.icon-btn {
  padding: 0.35rem 0.7rem;
  font-size: 0.8125rem;
}

button.icon-btn .icon-btn-text {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 24px;
}
/* 
#goToCheckoutButton {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: white !important;
} */

/* react-image-gallery */
.image-gallery-slide {
  border-radius: 4px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  font-size: 3em;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: #ccc;
}

@media (max-width: 667px) {
  .image-gallery-image img {
    max-width: 280px;
    width: 100%;
  }
}

/* Date picker */
button.SingleDatePickerInput_calendarIcon:hover {
  background: 0 0;
}

button.DayPickerNavigation_button__default:focus,
button.DayPickerNavigation_button__default:hover {
  background: white;
}

button.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--button-primary-bg-color);
  border: 1px solid var(--button-primary-border-color);
  color: var(--button-primary-color);
}

img {
  image-rendering: -webkit-optimize-contrast;
}

/* Cookies info page */
.cookies__container {
  max-width: 1024px;
  width: 100%;
  padding: 1rem;
  height: auto !important;
  margin: 0 auto 2rem;
}

/* Image gallery */
.image-gallery-slide img {
  border-radius: 4px;
  margin: 0 auto;
  display: block;
}

/*------------------------------------*/

/*------------*/
/* App styles */
/*------------*/
.App {
  position: relative;
}

#root {
}

#root > div {
}

/* TEXTS */
.terms__text,
.privacy__text {
  height: auto !important;
  margin: 0 auto;
  max-width: 1024px;
  padding: 2rem 1rem;
}

/*---------------------*/
/* Article list styles */
/*---------------------*/

.open-price-select {
  font-size: 18px;
  line-height: 24px;
  color: #565a5c;
  padding: 13px 12px 11px;
  border: 1px solid #dbdbdb;
}

.search__input .MuiInput-underline:after {
  border-color: inherit;
}

.search__input input[type="search"]::-webkit-search-decoration,
.search__input input[type="search"]::-webkit-search-cancel-button,
.search__input input[type="search"]::-webkit-search-results-button,
.search__input input[type="search"]::-webkit-search-results-decoration {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  color: inherit;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAlUlEQVRIie2UPQqAMAxGn97Bovc/iS7i36KDx6lLBRWVJFVw8JsKTb4XmjTw64uqgB5wihwHDEApCW4BD0xCiAuxHmgkgAwYQ8IMFIrYXAKQQszmEki0+R3kMfNV2yZOh7Nm0m61rVpVeRoBTSJyd3r1ic4aqvknavOrOzVEMopmiGbOTZAO+7JrJYAa27ruQ+6vj2kBkvdNfrIpLCQAAAAASUVORK5CYII=); */
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNMTEgMC43SDEzVjIzLjNIMTF6IiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUuMDAxIDEyIDEyKSI+PC9wYXRoPjxwYXRoIGQ9Ik0wLjcgMTFIMjMuM1YxM0gwLjd6IiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUuMDAxIDEyIDEyKSI+PC9wYXRoPjwvc3ZnPg==); */
  background-image: url(./images/close.svg);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

/* Add to cart button */
button.MuiButton-textPrimary {
  /* padding-bottom: 0; */
  padding: 0 !important;
}

button.MuiButton-textPrimary:hover {
  opacity: 0.8;
}

button.MuiButton-textPrimary span.MuiButton-label {
  background-color: black;
  padding: 0.35rem 0.8rem;
  border-radius: 4px;
  color: white;
  line-height: normal;
}

a:focus,
button:focus {
  outline-offset: 2px;
  outline: 2px solid #339af0;
  /*border-radius: 4px;*/
}

.mantine-DateRangePicker-day:focus {
  outline-offset: -2px;
  outline: 2px solid #E668A7;
  border-radius: 4px;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  outline: 0px;
}

.mantine-1jqdsep[data-in-range] {
  background-color: #e2e8f0;
}

.mantine-Input-input:focus {
  border-width: 2px !important;
}