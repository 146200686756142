.mantine-Calendar-day[data-selected="true"] {
    background-color: var(--theme-primary-color) !important;
    color: var(--theme-primary-color-text) !important;
}

.primaryButton {
    background-color: var(--theme-primary-color);
    color: var(--theme-primary-color-text);
}

.primaryButtonOutline {
    border-color: var(--theme-primary-color);
    color: var(--theme-primary-color);
}

.primaryButton:hover {
    background-color: var(--theme-primary-hover-color);
}

.mantine-Input-rightSection {
    height: 2.5rem;
}

.mantine-Checkbox-input[type="checkbox"] {
    display: block;
}

.mantine-Checkbox-input, .mantine-Input-input {
    /*border: 1px solid #8A8C93;*/
}

.mantine-TextInput-label, .mantine-Checkbox-label, .mantine-NumberInput-label {
    font-size: 0.9rem;
}

.mantine-Stepper-stepIcon[data-completed="true"], .mantine-Stepper-separatorActive {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.mantine-Calendar-monthPickerControlActive {
    background-color: var(--theme-primary-color);
    color: var(--theme-primary-color-text);
}

.mantine-Calendar-monthPickerControlActive:hover {
    background-color: var(--theme-primary-hover-color);
}

.mantine-Stepper-stepWrapper div[data-progress="true"] {
    border-color: var(--theme-primary-color);
}

.pbCalendar div table tbody tr td {
    text-align: center !important;
    padding: 0.35rem;
}

.pbCalendar div table tbody tr td button {
    width: 100%;
}

.pbCalendar div table tbody tr td button:not(:disabled) {
    border: 1px solid var(--theme-primary-color);
}

.mantine-NumberInput-description {
    color: black;
}

.mantine-NumberInput-control {
    padding: 0 0.2rem 0 0.2rem !important;
}

.mantine-Checkbox-label, .mantine-Radio-label {
    font-size: .9rem;
    margin: 0;
    padding: 0 0 0 0.5rem;
}

.mantine-Checkbox-input:checked, .mantine-Radio-root[data-checked="true"] div div input {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}


@media screen and (max-width: 768px) {
    .mantine-Stepper-separator {
        margin-left: 0.26rem;
        margin-right: 0.26rem;
    }
}

.publicBookingModal .mantine-Modal-title {
    font-weight: 500;
}