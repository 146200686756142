.event-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
}

/* * {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid lightgray;
} */

/* .progressItemWrapper {
  margin: 0 auto;
  height: 100%;
  align-self: stretch;
}

.progressTitle {
  margin-bottom: 0.35rem;
}

.progressItem {
  padding: 0rem 0.55rem;
  height: auto;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  opacity: 0.2;
  width: 30px;
  margin-top: 0.7rem;
}

.disabledTitle {
  opacity: 0.2;
}

.activeProgress {
  width: 45px;
  height: auto;
  margin: 0 auto;
  padding: 0.4rem 1rem;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  align-self: center;
}

.bgBlur {
  filter: blur(3.5px);
  pointer-events: none;
}

.spinnerWrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 25%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.event-header {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 0rem;
  color: #000;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.event-icon {
  color: rgb(95, 95, 95);
}

/* .subHeader {
  font-size: 1.25rem;
}

.subHeader:first-letter {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row-middle {
  align-items: center;
} */

.event-column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.event-column span {
  text-transform: capitalize;
}

/* Calendar */
.event-calendar {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid rgb(236, 236, 236);
  height: auto;
  margin-top: -.6rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 8px;
  /* border: 1px solid #ced4da; */
}

.event-calendar .calendarHeader {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.6em 0;
  color: #f8f8f8;
  text-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-calendar .calendarHeader .event-icon {
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.event-icon:hover {
  transition: 0.1s ease-in-out;
  opacity: 0.6;
  cursor: pointer;
}
/* 
.monthtext {
  letter-spacing: 0.7px;
}

.yeartext {
  font-weight: 100;
  font-size: 1rem;
} */

.event-calendar .event-days {
  text-transform: lowercase;
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-size: 0.9rem;
  padding: 0rem 1rem;
  text-align: center;
}

.event-calendar .body .event-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px solid rgb(0, 0, 0); */
  overflow: hidden;
  cursor: pointer;
  /* background: rgb(255, 255, 255); */
  transition: 0.1s ease-in-out;
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  margin: 0.3125rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .event-calendar .event-days {
    padding: 0 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .event-calendar .body .event-cell {
    font-size: 1rem;
  }
}

.event-calendar .body .disabled {
  border: none;
  cursor: default;
  pointer-events: none;
  color: rgb(238, 238, 238);
}

.event-calendar .body .event-cell:hover {
  background: rgb(247, 247, 247);
  transition: 0.1s ease-in-out;
}

.event-calendar .body .occasion-selected {
  background: #4c6ef5;
  color: #fff;
  font-weight: 600;
}

.event-calendar .body .occasion-selected:hover {
  background: #4c6ef5;
  color: #fff;
  font-weight: 600;
}

.event-calendar .body .row {
  /* padding: 0.5rem; */
}

@media screen and (max-width: 768px) {
  .event-calendar .body .row {
    padding: 0.5rem;
  }
}

.event-calendar .body .row:last-child {
  border-bottom: none;
}

/* .price {
  font-weight: 600;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-top: 0.15rem;
}

.cellwrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
} */

.event-calendar .body .event-cell:hover .bg,
.event-calendar .body .occasion-selected .bg {
  opacity: 0.2;
  transition: 0.1s ease-in-out;
}

.event-calendar .body .event-column {
  flex-grow: 0;
  flex-basis: calc(92% / 7);
  width: calc(100% / 7);
}

div[class*="disabled"] {
  opacity: 0.2 !important;
  pointer-events: none;
  border: none !important;
}

/* .loadingwrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 41.3%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

button:not(:disabled):not(.occasion-selected) .event-number {
  color: #495057;
}

.event-cell[aria-disabled="false"]:not(:disabled) {
  border: 1.25px solid #92959B !important;
}

@media screen and (max-width: 767px) {
  .event-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .event-calendar .body .row {
    height: calc(26vw / 1.72);
  }
/* 
  .price {
    display: none;
  } */

  .event-calendar .body .event-cell {
    border-radius: 50%;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .event-number {
    height: 100%;
    display: flex;
    align-items: center;
  }
  /* .cellwrapper {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
  } */

  .event-calendar .body .occasion-selected {
    /* border: 0px solid rgb(75, 75, 75); */
    font-weight: 700;
    /* -webkit-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    -moz-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75); */
  }
}

.mantine-SimpleGrid-root .event-calendar .body {
  margin: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 768px) {
  .mantine-SimpleGrid-root .event-calendar .body {
    margin: 0;
  }
}
