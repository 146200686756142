.search-button {
  position: relative;
  background: none;
  border: none;
}

.search-button[data-isactive="true"] {
  margin-left: -0.8rem;
}

.search-backdrop[data-isactive="true"] {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  transition: all 0.15s ease-in-out;
  margin-left: calc(-50vw + 50%);
}

.search-wrapper {
  /* margin-top: 0.2rem; */
  padding: 0 4px;
  display: flex;
  position: relative;
  height: auto;
  /* margin-top: 1rem; */
  /* max-height: 2.9rem; */
}

.search-wrapper[data-isactive="true"] {
  position: absolute;
  left: calc(100% - 36rem);
  top: 0.9rem;
  max-height: 3rem;
  margin-top: -0.5rem;
  z-index: 2600;
}

.search-inner-wrapper {
  width: 100%;
}

.search-icon {
  color: var(--primary-text);
  width: 1.5rem;
  height: auto;
  vertical-align: middle;
  cursor: pointer;
  z-index: 999;
  /* transition: 0.1s ease-in-out; */
}

.search-icon[data-isactive="true"] {
  position: absolute;
  left: 2.7rem;
  bottom: 0;
  right: 1rem;
  top: 0.65rem;
}

.search-icon:hover {
  opacity: 0.6;
}

.search-icon[data-isactive="true"] path {
  fill: gray;
}

.search-icon[data-isactive="true"]:hover {
  opacity: 0.6;
}

.search-close {
  cursor: pointer;
  width: 1.5rem;
  height: 100%;
  opacity: 1;
  position: absolute;
  bottom: 0;
  /* top: -0.5rem; */
  right: 2rem;
  transition: 0.1s ease-in-out;
  display: none;
}

.search-close[data-isactive="true"] {
  display: block;
}

.search-close-icon {
  width: 2rem;
  height: auto;
  opacity: 1;
  position: relative;
  transition: 0.1s ease-in-out;
  display: none;
  right: 0.6rem;
}

.search-close-icon[data-isactive="true"] {
  display: block;
  /* margin-top: 0.4rem; */
}

.search-close-icon[data-isactive="true"]:hover {
  opacity: 0.6;
}
.search-close-icon path {
  stroke: gray;
}

.search-close-icon[data-isactive="true"] {
  opacity: 1;
  bottom: inherit;
  top: inherit;
}

.search-close-icon[data-isactive="false"] {
  opacity: 0;
}

.search-input {
  border-radius: 8px;
  /* border: 1px solid black; */
  border: none;
  opacity: 0;
  cursor: default;
  text-indent: 4.5rem;
  font-size: 1.25rem;
  display: none;
}

.search-input[data-isactive="true"] {
  padding: 0.75rem 0rem 0.75rem 0rem;
  border-radius: 8px;
  /* border: 1px solid black; */
  width: 30rem;
  opacity: 1;
  cursor: text;
  display: block;
}

.search-input[data-isactive="false"] {
  width: 2.3rem;
  opacity: 0;
  cursor: default;
}

.search-result-wrapper {
  position: absolute;
  width: 30rem;
  height: 100%;
  top: 3.5rem;
  bottom: 0;
  /* right: 0;
  left: 0; */
  z-index: 999;
  opacity: 0;
  display: flex;
  flex-direction: row;
  color: var(--primary-text);
}

@media screen and (max-width: 968px) {
  .search-input {
    font-size: 0.9rem;
  }
  .search-close {
    height: 2.8rem;
  }
  .search-wrapper {
    justify-self: end;
    padding: 0;
  }
  .search-wrapper[data-isactive="true"] {
    width: 95vw;
    position: absolute;
    top: 0.9rem;
    right: 0;
    left: 0;
    padding: 0;
    justify-content: center;
    margin: 0 auto;
  }

  .search-icon[data-isactive="true"] {
    top: 0.8rem;
    /* left: 2.5rem; */
  }

  .search-input[data-isactive="true"] {
    width: 100%;
    height: 3.5rem;
  }

  .search-result-wrapper {
    width: 95vw;
  }

  .items-wrapper {
    margin-top: 1rem;
  }
}

.search-category {
  height: auto;
  padding-left: 1rem;
  color: black;
}

.category-button-wrapper {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  height: 3.8rem;
}

.category-button {
  font-size: 1rem;
  display: flex;
  padding: 0.5rem 1.25rem;
  border-radius: 50px;
  transition: all 0.15s ease-in-out;
  border: none;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .category-button {
    min-width: 1rem;
    padding-right: 1rem;
  }
}

.category-button p {
  margin: 0;
}

.category-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.category-close-icon {
  margin-left: 0.6rem;
  margin-right: -0.3rem;
  font-size: 1.5rem;
}

.category-close-icon path {
  stroke: white;
}

.active-category {
  background-color: black;
  color: white;
}

.inactive-category {
  background-color: white;
  -webkit-box-shadow: inset 0px 0px 0px 2px rgb(0, 0, 0);
  -moz-box-shadow: inset 0px 0px 0px 2px rgb(0, 0, 0);
  box-shadow: inset 0px 0px 0px 2px rgb(0, 0, 0);
  color: black;
}

/* .inactiveCategory {
  opacity: 1;
  background-color: white;
  outline: solid 2px black;
  color: black;
} */
.items-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  height: min-content;
  border-radius: 6px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 600px;
  overflow-y: scroll;
  display: none;
}

.items-wrapper[data-isactive="true"] {
  display: block;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  margin-top: 0px;
  max-width: 70%;
  padding-left: 1rem;
}

.text-wrapper p {
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

.text-header {
  font-size: 1rem;
  color: var(--primary-text);
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.1rem;
}

.text-description {
  color: var(--primary-text);
  text-align: left;
  font-size: 0.9rem !important;
  padding: 0.1rem 0rem;
}

.text-price {
  color: var(--primary-text) !important;
  font-size: 0.9rem !important;
  font-weight: 400;
}
.search-result-wrapper[data-isactive="true"] {
  opacity: 1;
  height: 100%;
}

.search-result-wrapper[data-isactive="false"] {
  display: none;
}

.search-result {
  display: flex;
  height: auto;
  padding: 1rem;
  transition: all 0.15s ease-in-out;
}

.search-result:hover {
  /* opacity: 0.8; */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.items-wrapper hr {
  width: 94%;
  margin: 0 auto;
}

.search-result img {
  border-radius: 6px;
  object-fit: cover;
}

.search-result-btn {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  background: none;
}
