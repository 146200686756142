@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  font-family: Roboto, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

strong,
b {
  font-weight: 500;
}

hr {
  height: 1px;
  margin: 0;
  border: none;
  flex-shrink: 0;
  background-color: var(--border-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  color: inherit;
}

blockquote {
  border-left: 2px solid var(--border-color);
  margin-left: 8px;
  margin-right: 0;
  padding-left: 8px;
  color: var(--text-secondary);
  font-style: italic;
}

@media (max-width: 576px) {
  .page__content {
    padding: 10px !important;
  }
}

/*-----------------*/
/* Utility Classes */
/*-----------------*/
:root {
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
}

/* .app__content-container {
  min-height: 100vh;
} */

/* .page__container {
  min-height: auto !important;
} */

strong {
  font-weight: bold;
}
/* REACT DATES */
.DateInput {
  width: 100%;
}

.DateInput:hover:before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.DateInput:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.DateInput_input {
  font: inherit;
  width: 100%;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 3px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
}

.DateInput_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.DateInput_input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.DateInput_input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.SingleDatePicker {
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.1875em;
  align-items: center;
}

.SingleDatePickerInput__withBorder {
  border-radius: 0px;
  border: none;
}

.SingleDatePicker_picker {
  z-index: 10;
}

/* REACT-DATES CALENDAR */
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.CalendarDay__highlighted_calendar {
  background: #fff;
  color: #484848;
}

.CalendarDay__highlighted_calendar:hover {
  background: #ededed;
  color: #484848;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.header__logo-img {
  height: 50px;
  width: 50px;
}

@media (max-width: 667px) {
  .header__logo-img {
    height: 30px;
    width: 30px;
  }
}

/* Buttons */

button.icon-btn {
  padding: 0.35rem 0.7rem;
  font-size: 0.8125rem;
}

button.icon-btn .icon-btn-text {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 24px;
}
/* 
#goToCheckoutButton {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: white !important;
} */

/* react-image-gallery */
.image-gallery-slide {
  border-radius: 4px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  font-size: 3em;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: #ccc;
}

@media (max-width: 667px) {
  .image-gallery-image img {
    max-width: 280px;
    width: 100%;
  }
}

/* Date picker */
button.SingleDatePickerInput_calendarIcon:hover {
  background: 0 0;
}

button.DayPickerNavigation_button__default:focus,
button.DayPickerNavigation_button__default:hover {
  background: white;
}

button.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--button-primary-bg-color);
  border: 1px solid var(--button-primary-border-color);
  color: var(--button-primary-color);
}

img {
  image-rendering: -webkit-optimize-contrast;
}

/* Cookies info page */
.cookies__container {
  max-width: 1024px;
  width: 100%;
  padding: 1rem;
  height: auto !important;
  margin: 0 auto 2rem;
}

/* Image gallery */
.image-gallery-slide img {
  border-radius: 4px;
  margin: 0 auto;
  display: block;
}

/*------------------------------------*/

/*------------*/
/* App styles */
/*------------*/
.App {
  position: relative;
}

#root {
}

#root > div {
}

/* TEXTS */
.terms__text,
.privacy__text {
  height: auto !important;
  margin: 0 auto;
  max-width: 1024px;
  padding: 2rem 1rem;
}

/*---------------------*/
/* Article list styles */
/*---------------------*/

.open-price-select {
  font-size: 18px;
  line-height: 24px;
  color: #565a5c;
  padding: 13px 12px 11px;
  border: 1px solid #dbdbdb;
}

.search__input .MuiInput-underline:after {
  border-color: inherit;
}

.search__input input[type="search"]::-webkit-search-decoration,
.search__input input[type="search"]::-webkit-search-cancel-button,
.search__input input[type="search"]::-webkit-search-results-button,
.search__input input[type="search"]::-webkit-search-results-decoration {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  color: inherit;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAlUlEQVRIie2UPQqAMAxGn97Bovc/iS7i36KDx6lLBRWVJFVw8JsKTb4XmjTw64uqgB5wihwHDEApCW4BD0xCiAuxHmgkgAwYQ8IMFIrYXAKQQszmEki0+R3kMfNV2yZOh7Nm0m61rVpVeRoBTSJyd3r1ic4aqvknavOrOzVEMopmiGbOTZAO+7JrJYAa27ruQ+6vj2kBkvdNfrIpLCQAAAAASUVORK5CYII=); */
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNMTEgMC43SDEzVjIzLjNIMTF6IiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUuMDAxIDEyIDEyKSI+PC9wYXRoPjxwYXRoIGQ9Ik0wLjcgMTFIMjMuM1YxM0gwLjd6IiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUuMDAxIDEyIDEyKSI+PC9wYXRoPjwvc3ZnPg==); */
  background-image: url(/ticketshop/static/media/close.79e64da0.svg);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

/* Add to cart button */
button.MuiButton-textPrimary {
  /* padding-bottom: 0; */
  padding: 0 !important;
}

button.MuiButton-textPrimary:hover {
  opacity: 0.8;
}

button.MuiButton-textPrimary span.MuiButton-label {
  background-color: black;
  padding: 0.35rem 0.8rem;
  border-radius: 4px;
  color: white;
  line-height: normal;
}

a:focus,
button:focus {
  outline-offset: 2px;
  outline: 2px solid #339af0;
  /*border-radius: 4px;*/
}

.mantine-DateRangePicker-day:focus {
  outline-offset: -2px;
  outline: 2px solid #E668A7;
  border-radius: 4px;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  outline: 0px;
}

.mantine-1jqdsep[data-in-range] {
  background-color: #e2e8f0;
}

.mantine-Input-input:focus {
  border-width: 2px !important;
}
.search-button {
  position: relative;
  background: none;
  border: none;
}

.search-button[data-isactive="true"] {
  margin-left: -0.8rem;
}

.search-backdrop[data-isactive="true"] {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  transition: all 0.15s ease-in-out;
  margin-left: calc(-50vw + 50%);
}

.search-wrapper {
  /* margin-top: 0.2rem; */
  padding: 0 4px;
  display: flex;
  position: relative;
  height: auto;
  /* margin-top: 1rem; */
  /* max-height: 2.9rem; */
}

.search-wrapper[data-isactive="true"] {
  position: absolute;
  left: calc(100% - 36rem);
  top: 0.9rem;
  max-height: 3rem;
  margin-top: -0.5rem;
  z-index: 2600;
}

.search-inner-wrapper {
  width: 100%;
}

.search-icon {
  color: var(--primary-text);
  width: 1.5rem;
  height: auto;
  vertical-align: middle;
  cursor: pointer;
  z-index: 999;
  /* transition: 0.1s ease-in-out; */
}

.search-icon[data-isactive="true"] {
  position: absolute;
  left: 2.7rem;
  bottom: 0;
  right: 1rem;
  top: 0.65rem;
}

.search-icon:hover {
  opacity: 0.6;
}

.search-icon[data-isactive="true"] path {
  fill: gray;
}

.search-icon[data-isactive="true"]:hover {
  opacity: 0.6;
}

.search-close {
  cursor: pointer;
  width: 1.5rem;
  height: 100%;
  opacity: 1;
  position: absolute;
  bottom: 0;
  /* top: -0.5rem; */
  right: 2rem;
  transition: 0.1s ease-in-out;
  display: none;
}

.search-close[data-isactive="true"] {
  display: block;
}

.search-close-icon {
  width: 2rem;
  height: auto;
  opacity: 1;
  position: relative;
  transition: 0.1s ease-in-out;
  display: none;
  right: 0.6rem;
}

.search-close-icon[data-isactive="true"] {
  display: block;
  /* margin-top: 0.4rem; */
}

.search-close-icon[data-isactive="true"]:hover {
  opacity: 0.6;
}
.search-close-icon path {
  stroke: gray;
}

.search-close-icon[data-isactive="true"] {
  opacity: 1;
  bottom: inherit;
  top: inherit;
}

.search-close-icon[data-isactive="false"] {
  opacity: 0;
}

.search-input {
  border-radius: 8px;
  /* border: 1px solid black; */
  border: none;
  opacity: 0;
  cursor: default;
  text-indent: 4.5rem;
  font-size: 1.25rem;
  display: none;
}

.search-input[data-isactive="true"] {
  padding: 0.75rem 0rem 0.75rem 0rem;
  border-radius: 8px;
  /* border: 1px solid black; */
  width: 30rem;
  opacity: 1;
  cursor: text;
  display: block;
}

.search-input[data-isactive="false"] {
  width: 2.3rem;
  opacity: 0;
  cursor: default;
}

.search-result-wrapper {
  position: absolute;
  width: 30rem;
  height: 100%;
  top: 3.5rem;
  bottom: 0;
  /* right: 0;
  left: 0; */
  z-index: 999;
  opacity: 0;
  display: flex;
  flex-direction: row;
  color: var(--primary-text);
}

@media screen and (max-width: 968px) {
  .search-input {
    font-size: 0.9rem;
  }
  .search-close {
    height: 2.8rem;
  }
  .search-wrapper {
    justify-self: end;
    padding: 0;
  }
  .search-wrapper[data-isactive="true"] {
    width: 95vw;
    position: absolute;
    top: 0.9rem;
    right: 0;
    left: 0;
    padding: 0;
    justify-content: center;
    margin: 0 auto;
  }

  .search-icon[data-isactive="true"] {
    top: 0.8rem;
    /* left: 2.5rem; */
  }

  .search-input[data-isactive="true"] {
    width: 100%;
    height: 3.5rem;
  }

  .search-result-wrapper {
    width: 95vw;
  }

  .items-wrapper {
    margin-top: 1rem;
  }
}

.search-category {
  height: auto;
  padding-left: 1rem;
  color: black;
}

.category-button-wrapper {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  padding-bottom: 1rem;
  height: 3.8rem;
}

.category-button {
  font-size: 1rem;
  display: flex;
  padding: 0.5rem 1.25rem;
  border-radius: 50px;
  transition: all 0.15s ease-in-out;
  border: none;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .category-button {
    min-width: 1rem;
    padding-right: 1rem;
  }
}

.category-button p {
  margin: 0;
}

.category-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.category-close-icon {
  margin-left: 0.6rem;
  margin-right: -0.3rem;
  font-size: 1.5rem;
}

.category-close-icon path {
  stroke: white;
}

.active-category {
  background-color: black;
  color: white;
}

.inactive-category {
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px rgb(0, 0, 0);
  color: black;
}

/* .inactiveCategory {
  opacity: 1;
  background-color: white;
  outline: solid 2px black;
  color: black;
} */
.items-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  height: -webkit-min-content;
  height: min-content;
  border-radius: 6px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 600px;
  overflow-y: scroll;
  display: none;
}

.items-wrapper[data-isactive="true"] {
  display: block;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  margin-top: 0px;
  max-width: 70%;
  padding-left: 1rem;
}

.text-wrapper p {
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

.text-header {
  font-size: 1rem;
  color: var(--primary-text);
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.1rem;
}

.text-description {
  color: var(--primary-text);
  text-align: left;
  font-size: 0.9rem !important;
  padding: 0.1rem 0rem;
}

.text-price {
  color: var(--primary-text) !important;
  font-size: 0.9rem !important;
  font-weight: 400;
}
.search-result-wrapper[data-isactive="true"] {
  opacity: 1;
  height: 100%;
}

.search-result-wrapper[data-isactive="false"] {
  display: none;
}

.search-result {
  display: flex;
  height: auto;
  padding: 1rem;
  transition: all 0.15s ease-in-out;
}

.search-result:hover {
  /* opacity: 0.8; */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.items-wrapper hr {
  width: 94%;
  margin: 0 auto;
}

.search-result img {
  border-radius: 6px;
  object-fit: cover;
}

.search-result-btn {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  background: none;
}

.headerV2 {
  width: 100%;
  display: flex;
  /* position: absolute; */
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
}

.navV2 {
  /* display: grid;
    grid-template-columns: auto 1fr auto; */
  /* justify-items: center; */
  /* align-items: center; */
  padding-left: 1rem;
  width: 100%;
  transition: all 0.25s ease-in-out;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
}

.navV2[data-isactive="false"]::before {
  position: absolute;
  width: 100%;
  height: 5rem;
  margin-left: -1rem;
  z-index: -1;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
}

@media screen and (max-width: 768px) {
  .navV2[data-isactive="false"]::before {
    margin: 0;
  }
}

.navMenuWrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  transition: all 0.25s ease-in-out;
}

.headerFixed {
  position: fixed;
  top: 0;
  z-index: 200;
}

.headerDefault {
  position: absolute;
  top: 0;
  z-index: 200;
}

.headerTransparent {
  background: none;
  color: white;
}

.darkColor {
  color: var(--header-link-color-dark);
}

.darkColor {
  color: var(--header-link-color-dark);
}

.lightColor {
  color: var(--header-link-color-light) !important;
}

.lightColor:hover {
  color: var(--link-hover-color) !important;
}

.darkColor:hover {
  color: var(--link-hover-color) !important;
}

.nav-headerLoginButton {
  align-self: center;
}

.navList {
  text-decoration: none;
  width: 100%;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-rows: 1fr;
  align-items: center;
  color: white;
  font-family: var(--heading-fonts);
  height: 100%;
  margin: 0 !important;
  padding: 0;
}

.dropdownLabel {
  font-family: var(--heading-fonts);
  font-size: inherit;
}

.menuItem-container {
  display: flex;
  align-items: center;
  /* margin-top: 1rem !important;
    margin-bottom: 1rem !important; */
  cursor: pointer;
  position: relative;
}

.navSubCategoryWrapper {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
}

.navSubCategoryWrapper label {
  color: var(--header-color);
  font-size: 1rem;
  text-align: left;
}

.navSubCategoryWrapper li {
  padding: 0;
}

.navLeftColumn {
  display: flex;
  align-items: center;
  justify-self: start;
}

.navMiddleColumnWrapper {
  justify-self: center;
  align-self: center;
  width: 100%;
}

.navMiddleColumn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.navRightColumn {
  display: flex;
  justify-self: end;
  align-items: center;
}

.navRightColumn .header__cart-button {
  padding-right: 1rem;
}

.headerIcon {
  position: relative;
  /* top: 0.2rem; */
}

.menuItem-wrapper {
  display: none;
  cursor: default;
  list-style: none;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.header__cart-button a {
  padding-right: 1rem;
  align-items: center;
  display: flex;
}

.menuItem-wrapper[data-isshowing="true"] {
  position: absolute;
  top: calc(100% + 8px);
  /* left: 0;
    right: 0;
    margin: 0 auto; */
  background-color: var(--theme-primary-color);
  min-width: 12.5rem;
  width: auto;
  height: auto;
  padding: 1rem 1.8rem 1.25rem 1rem;
  border-radius: 8px;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  grid-row-gap: 0.7rem;
  row-gap: 0.7rem;
}

.menuItem-wrapper::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  content: "";
}

.menuItem-wrapper::after {
  position: absolute;
  content: "";
  top: calc(-1 * 8px);
  left: 0;
  width: 100%;
  height: 8px;
}

.navLeftColumn .header__logo_link .header__logo-img {
  height: 100%;
}

.logoV2 {
  padding: 1rem 0;
}

.menuItem-wrapper li {
  width: -webkit-min-content;
  width: min-content;
  padding: 0;
}

.menuItem-container {
  white-space: nowrap;
}

/* .menuItem-item {
  color: white;
}

.menuItem-item:visited {
  color: white;
} */

.menuItem-link {
  position: relative;
}

.menuItem-item:hover {
}

.dropDownMenu {
}

.menuItem-underline {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 15px;
}

.menuItem-lightUnderline {
  opacity: 0.85;
}

.menuItem-darkUnderline {
  background: rgb(54, 54, 54);
  opacity: 0.85;
}

.menuItem-underline-color {
  background: rgb(54, 54, 54);
}

/* Mobile Menu */
.hamburgerMenuWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.mobileMenuV2Wrapper {
  display: none;
}

.mobileMenuV2 {
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
}

.mobileMenuV2[data-isopen="true"] {
  /* left: 2.6rem; */
  top: -0.8rem;
}

.hamburgerMenuLineFirst {
  height: 5px;
  width: 2.5rem;
}

.hamburgerMenuLineMiddle {
  height: 5px;
  width: 2.5rem;
}

.hamburgerMenuLineLast {
  height: 5px;
  width: 2.5rem;
}

.mobileMenuV2 div {
}

.darkColorLine {
  background-color: var(--header-link-color-light);
}

.lightColorLine {
  background-color: var(--header-link-color-dark);
}

.darkHamburgerMenu {
  color: var(--header-link-color-dark);
  stroke: var(--header-link-color-dark);
}

.lightHamburgerMenu {
  color: var(--header-link-color-light);
  stroke: var(--header-link-color-light);
}

.menuLinePath[data-isopen="true"] {
  /* stroke: var(--header-link-color-light); */
}

.countdownTimer {
  padding-right: 0.5rem;
}

.mobileMenuOverlay {
  position: fixed;
  width: 90vw;
  height: auto;
  min-height: 100vh;
  top: 0;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 200;
  overflow: auto;
}

.mobileMenuBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.692);
  z-index: 190;
}

.subMenuCloseButton {
  color: var(--button-primary-color);
  margin-left: auto;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.subMenuCloseButton:hover {
  color: var(--theme-primary-color);
}

@media screen and (max-width: 768px) {
  .header__cart-button {
    /* right: 1rem; */
  }

  .navV2 {
    /* height: 3.25rem; */
    height: 100%;
  }

  .logoV2 {
    max-height: 2.25rem !important;
    padding: 0;
  }

  .mobileSettingsWrapper {
    position: absolute;
    top: 0.8rem;
    right: 2.5rem;
  }

  .mobileSettingsWrapper button {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  .mobileLoginWrapper {
    position: absolute;
    z-index: 200;
    bottom: 1rem;
    left: 2rem;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  .navList {
    color: black;
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    font-size: 125%;
    padding-top: 7rem;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
  }

  .navMenuWrapper {
    display: none;
  }

  .navV2 {
    padding: 0;
  }

  .menuItem-underline {
    width: 4px;
    height: 100%;
    top: 0;
    left: -0.7rem;
  }

  .dropdownLabel {
    font-size: 1.25rem;
  }

  .menuItem-wrapper[data-isshowing="true"] {
    width: 100%;
    position: relative;
    background-color: white;
    padding-top: 0;
  }

  a.menuItem-link {
    color: black !important;
  }

  .subMenuCloseButton {
    color: black;
  }

  .menuItem-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .mobileMenuV2Wrapper {
    /* width: 90vw; */
    height: 100%;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 40% 1px 20% 20%;
  }

  .mobileMenuV2Wrapper .header__cart-button {
    position: inherit;
  }

  .mobileMenuV2 {
    background: none;
    border: none;
    top: 0.25rem;
  }

  .header__logo-img {
    width: auto;
    object-fit: contain;
  }

  .header__logo_link {
    width: -webkit-max-content;
    width: max-content;
    justify-self: start;
    margin-left: 0.5rem;
  }

  .header__user-button {
    color: var(--header-link-color-light);
    padding-left: 0;
  }

  .search-icon[data-ison="true"] {
    width: 1.7rem;
    height: 1.7rem;
  }

  .page__content div div[class^="makeStyles-header-"] {
    z-index: 890;
  }
}

#main-content-link {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0px;
  background-color: var(--theme-primary-color);
  color: var(--button-primary-color);
  pointer-events: none;
}
#main-content-link:focus-within {
  width: 100vw;
  opacity: 1;
  padding: 1rem;
  z-index: 999;
}

.mantine-Drawer-root {
  z-index: 1000;
}

.mantine-Drawer-closeButton {
  margin-right: -0.6rem;
}

.mantine-Indicator-indicator {
  background-color: var(--button-primary-bg-color);
  color: var(--button-primary-color);
}

.mantine-Drawer-title {
  font-family: inherit;
  font-size: 1.375rem;
  font-weight: bold;
}

.mantine-Drawer-closeButton {
  height: 3rem;
  width: 3rem;
}

.mantine-Drawer-closeButton svg {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
}

.icon-tabler-alert-circle {
  color: #fa5252;
}

.mantine-Drawer-body {
  height: 100%;
}

/* ProductV2 */
.mantine-NumberInput-root {
  height: 100%;
}

.mantine-NumberInput-wrapper {
  height: 100%;
}

.mantine-NumberInput-wrapper .mantine-NumberInput-input {
  height: 42px;
}

.regularButton {
  background-color: var(--button-primary-bg-color);
  color: var(--button-primary-color);
  transition: all 0.1s ease-in-out;
}

.regularButton:disabled {
  pointer-events: none;
}

.regularButton:disabled:hover {
  pointer-events: none;
}

.regularButton:hover {
  background-color: var(--button-primary-hover-bg-color);
}

.productSelector {
  color: var(--button-primary-bg-color) !important;
  object-fit: cover;
}

.productOutlineImage {
  color: var(--button-primary-bg-color) !important;
}

.productSelector .mantine-Select-root {
  width: 100% !important;
}

.mantine-InputWrapper-label {
  padding-top: 0;
}

.mantine-NumberInput-input {
  /*font-weight: bold;*/
}

.mantine-Image-root {
  height: auto;
}

.mantine-Image-figure {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.productSelector .mantine-Image-imageWrapper .mantine-Image-image {
  height: 6rem !important;
}

@media screen and (max-width: 768px) {
  .productSelector .mantine-Image-imageWrapper .mantine-Image-image {
    height: 6rem !important;
  }
}

.groupImage[data-isselected="true"] figure div img {
  box-shadow: 0 0 0 3px;
}

.personalImage img {
  height: 100% !important;
}

.productDescription h1 {
  font-size: 1rem;
}

.mantine-TextInput-input, .mantine-Autocomplete-input, .mantine-Autocomplete-dropdown {
  border: 1px solid #8A8C93;
}

.mantine-Dropzone-root {
  border: 1.25px dashed #8A8C93;
}

.mantine-DateRangePicker-input, .mantine-1bsq9rt {
  border: 1px solid #8A8C93;
}

.mantine-TextInput-error, .mantine-Autocomplete-error, .mantine-Textarea-error {
  font-weight: bold;
}


.mantine-Image-figure,
.mantine-Image-imageWrapper {
    height: 100%;
    width: 100%;
}

/*
.mantine-SimpleGrid-root p {
  margin: 0;
} */

/* .mantime-Calendar-cell {
  padding: 0.5rem !important;
} */

.mantine-Grid-root {
    flex-basis: auto;
}

.mantine-LoadingOverlay-root svg {
    stroke: #5c7cfa !important;
}

.__mantine-ref-weekend:not(:disabled):not(.__mantine-ref-outside):not(.mantine-Calendar-selected) {
    color: #495057 !important;
}

.__mantine-ref-weekend:not(:disabled):not(.__mantine-Calendar-outside) {
    color: #ced4da !important;
}

.__mantine-ref-selected:not(:disabled) {
    color: white !important;
}

.mantine-Calendar-day {
    font-weight: 500;
}

button.mantine-Calendar-day:disabled {
    opacity: 0.5;
}

button.mantine-Calendar-day:not(:disabled) {
    color: #495057;
}

button.__mantine-Calendar-outside {
    color: #ced4da !important;
}

button.disabledDate {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
}

.event-articleButton {
    position: relative;
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgb(53, 53, 53);
    background: none;
    border: 0 !important;
    /* -webkit-transform: translate(200%, -7%);
    transform: translate(200%, -7%); */
    z-index: 100;
}

.event-articleButton2:hover {
    opacity: 0.6;
    cursor: pointer;
}

.event-articleButton21 {
    position: relative;
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgb(53, 53, 53);
    margin: 0 0rem;
    /* -webkit-transform: translate(50%, -7%);
    transform: translate(50%, -7%); */
    opacity: 0;
    z-index: 50;
}

@media screen and (max-width: 768px) {
    .event-articleRightColumnToggle {
        min-width: 150px;
    }
}

/* ToggleButton settings */
.event-articleRightColumnToggle {
    position: relative;
    height: auto;
    margin-top: 1rem;
    min-width: 150px;
}

.event-switch {
    width: 40px;
    height: 40px;
    background-color: rgba(206, 206, 206, 0.4);
    position: absolute;
    left: 75%;
    border-radius: 50px;
    cursor: pointer;
    max-width: 110px;
}

.event-switch[data-ison="true"] {
    background-color: rgba(206, 206, 206, 0.4);
    position: absolute;
    left: 25%;
    /* width: 140px; */
    width: 100%;
    height: 40px;
}

.event-handle {
    position: absolute;
    top: 0%;
    left: 75%;
    z-index: 2;
    width: 40px;
    height: 40px;
    background-color: rgb(219, 219, 219);
    border-radius: 40px;
    cursor: pointer;
}

/* .event-handle[data-ison="true"] {
  left: 70%;
} */

.event-handle2 {
    position: absolute;
    top: 0%;
    left: 75%;
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: rgb(219, 219, 219);
    border-radius: 40px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.event-handle2[data-ison="true"] {
    left: 25%;
}

.event-count {
    position: absolute;
    /* top: 0%; */
    left: 60%;
    margin: 0 auto;
    color: rgb(53, 53, 53);
    font-size: 1.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0;
    text-align: right;
}

.event-count[data-ison="true"] {
    left: 0;
    margin-top: 0.2rem;
    /* left: 46%; */
    opacity: 1;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}

.event-articleButtonRemove {
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    border: 0;
    background: none;
}

.event-articleButton:hover {
    opacity: 0.6;
    cursor: pointer;
}

.event-articleButtonRemove:hover {
    opacity: 0.6;
    cursor: pointer;
}

.event-hideScreen {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

@media screen and (max-width: 768px) {
    .mantine-Primary-btn,
    .mantine-Primary-btn-success {
        height: 52px;
    }
}


.mantine-Col-root .articleButtonRemove {
    background-color: transparent;
    border: none;
}

.mantine-Col-root .articleRightColumnToggle .event-count {
    position: absolute;
    left: 25%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    /* z-index: 999 !important; */
    margin-top: 0.2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    opacity: 0;
    transition: all 0.1s ease-in-out;
}

.mantine-Col-root .articleRightColumnToggle .event-count[data-ison="true"] {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    z-index: 999 !important;
    margin-top: 0.2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    opacity: 1;
}

.mantine-Badge-inner {
    font-weight: 700;
}
.event-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
}

/* * {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid lightgray;
} */

/* .progressItemWrapper {
  margin: 0 auto;
  height: 100%;
  align-self: stretch;
}

.progressTitle {
  margin-bottom: 0.35rem;
}

.progressItem {
  padding: 0rem 0.55rem;
  height: auto;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  opacity: 0.2;
  width: 30px;
  margin-top: 0.7rem;
}

.disabledTitle {
  opacity: 0.2;
}

.activeProgress {
  width: 45px;
  height: auto;
  margin: 0 auto;
  padding: 0.4rem 1rem;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  align-self: center;
}

.bgBlur {
  filter: blur(3.5px);
  pointer-events: none;
}

.spinnerWrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 25%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.event-header {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 0rem;
  color: #000;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.event-icon {
  color: rgb(95, 95, 95);
}

/* .subHeader {
  font-size: 1.25rem;
}

.subHeader:first-letter {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row-middle {
  align-items: center;
} */

.event-column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.event-column span {
  text-transform: capitalize;
}

/* Calendar */
.event-calendar {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid rgb(236, 236, 236);
  height: auto;
  margin-top: -.6rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 8px;
  /* border: 1px solid #ced4da; */
}

.event-calendar .calendarHeader {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.6em 0;
  color: #f8f8f8;
  text-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-calendar .calendarHeader .event-icon {
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.event-icon:hover {
  transition: 0.1s ease-in-out;
  opacity: 0.6;
  cursor: pointer;
}
/* 
.monthtext {
  letter-spacing: 0.7px;
}

.yeartext {
  font-weight: 100;
  font-size: 1rem;
} */

.event-calendar .event-days {
  text-transform: lowercase;
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-size: 0.9rem;
  padding: 0rem 1rem;
  text-align: center;
}

.event-calendar .body .event-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px solid rgb(0, 0, 0); */
  overflow: hidden;
  cursor: pointer;
  /* background: rgb(255, 255, 255); */
  transition: 0.1s ease-in-out;
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  margin: 0.3125rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .event-calendar .event-days {
    padding: 0 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .event-calendar .body .event-cell {
    font-size: 1rem;
  }
}

.event-calendar .body .disabled {
  border: none;
  cursor: default;
  pointer-events: none;
  color: rgb(238, 238, 238);
}

.event-calendar .body .event-cell:hover {
  background: rgb(247, 247, 247);
  transition: 0.1s ease-in-out;
}

.event-calendar .body .occasion-selected {
  background: #4c6ef5;
  color: #fff;
  font-weight: 600;
}

.event-calendar .body .occasion-selected:hover {
  background: #4c6ef5;
  color: #fff;
  font-weight: 600;
}

.event-calendar .body .row {
  /* padding: 0.5rem; */
}

@media screen and (max-width: 768px) {
  .event-calendar .body .row {
    padding: 0.5rem;
  }
}

.event-calendar .body .row:last-child {
  border-bottom: none;
}

/* .price {
  font-weight: 600;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-top: 0.15rem;
}

.cellwrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
} */

.event-calendar .body .event-cell:hover .bg,
.event-calendar .body .occasion-selected .bg {
  opacity: 0.2;
  transition: 0.1s ease-in-out;
}

.event-calendar .body .event-column {
  flex-grow: 0;
  flex-basis: calc(92% / 7);
  width: calc(100% / 7);
}

div[class*="disabled"] {
  opacity: 0.2 !important;
  pointer-events: none;
  border: none !important;
}

/* .loadingwrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 41.3%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

button:not(:disabled):not(.occasion-selected) .event-number {
  color: #495057;
}

.event-cell[aria-disabled="false"]:not(:disabled) {
  border: 1.25px solid #92959B !important;
}

@media screen and (max-width: 767px) {
  .event-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .event-calendar .body .row {
    height: calc(26vw / 1.72);
  }
/* 
  .price {
    display: none;
  } */

  .event-calendar .body .event-cell {
    border-radius: 50%;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .event-number {
    height: 100%;
    display: flex;
    align-items: center;
  }
  /* .cellwrapper {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
  } */

  .event-calendar .body .occasion-selected {
    /* border: 0px solid rgb(75, 75, 75); */
    font-weight: 700;
    /* -webkit-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    -moz-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75); */
  }
}

.mantine-SimpleGrid-root .event-calendar .body {
  margin: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 768px) {
  .mantine-SimpleGrid-root .event-calendar .body {
    margin: 0;
  }
}

.productChooseBtn {
    border: 1px solid var(--theme-primary-color);
    color: var(--theme-primary-color);
}
.mantine-Slider-bar {
  background-color: var(--theme-primary-color) !important;
}

.mantine-DateRangePicker-day[data-selected="true"] {
  background-color: var(--theme-primary-color) !important;
}

/*.mantine-Overlay-root {*/
/*    z-index: 910;*/
/*}*/

.mantine-Modal-modal {
  z-index: 911;
}

.mantine-Slider-track {
  width: calc(99% + 8px);
  left: -2px;
}
.mantine-Slider-track::before {
  border: 1px solid #8A8C93;
  background-color: rgb(255, 255, 255);
  width: calc(99% + 8px);
  left: -2px;
}

.mantine-Slider-thumb:nth-of-type(2) {
  -webkit-transform: translate(-30%, -50%);
          transform: translate(-30%, -50%);
}

.mantine-Slider-thumb:nth-of-type(3) {
  -webkit-transform: translate(-80%, -50%);
          transform: translate(-80%, -50%);
}

.mantine-DateRangePicker-wrapper,
.mantine-DateRangePicker-input {
  height: 42px !important;
  min-height: 42px !important;
  font-size: 1rem;
}

.filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem;
}

.filter-input {
  font-size: 16px;
  font-weight: normal;
  padding: 6px 16px;
}
.filter-input:hover {
  cursor: pointer;
}

.filter-input-unmarked {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  border: 2px solid black !important;
  border-radius: 5rem;
  color: rgb(0, 0, 0);
  border: none;
}
.filter-input-unmarked:hover {
  background-color: rgb(230, 230, 230);
}

.filter-input-marked {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border: 2px solid black !important;
  color: white;
  border-radius: 5rem;
}
input[type="checkbox"] {
  display: none;
}

.clear-filter {
  margin: 1rem;
}

.clear-filter-btn {
  background-color: #e6e6e6;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(0, 0, 0);
  border: none;
}
.clear-filter-btn:hover {
  background-color: rgb(230, 230, 230);
}

.icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2.5rem;
}

* {
    box-sizing: border-box;
}

body {
    /* font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; */
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid lightgray;
}

/* .progressWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-items: space-between;
  justify-content: space-between;
  align-self: space-between;
  justify-self: space-between;
  width: 15vw;
} */

.progressItemWrapper {
    margin: 0 auto;
    height: 100%;
    align-self: stretch;
}

.progressTitle {
    margin-bottom: 0.35rem;
}

.progressItem {
    padding: 0rem 0.55rem;
    height: auto;
    border-radius: 50%;
    background-color: rgb(56, 56, 56);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
    opacity: 0.2;
    width: 30px;
    margin-top: 0.7rem;
}

.disabledTitle {
    opacity: 0.2;
}

.activeProgress {
    width: 45px;
    height: auto;
    margin: 0 auto;
    padding: 0.4rem 1rem;
    border-radius: 50%;
    background-color: rgb(56, 56, 56);
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    align-self: center;
}

.legend {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.legend-iconwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.legend-text {
    margin: 0.5rem 0.5rem 0 0.5rem;
}

/* .legend-current {
  width: 25px;
  height: 25px;
  background-color: grey;
  border-radius: 50%;
} */

.legend-chosen {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: solid 3px rgb(56, 56, 56);
    border-radius: 5px;
}

.legend-available {
    width: 25px;
    height: 25px;
    background-color: rgb(135, 218, 97);
    border-radius: 5px;
}

.legend-low {
    width: 25px;
    height: 25px;
    background-color: rgb(224, 222, 87);
    border-radius: 5px;
}

.legend-full {
    width: 25px;
    height: 25px;
    background-color: rgb(207, 79, 79);
    border-radius: 5px;
}

.calendar-header {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
}

.calendar-subHeader {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.calendar-subHeader:first-letter {
    text-transform: capitalize;
}

.calendar-row {
    margin: 1rem .7rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}

.row-middle {
    align-items: center;
}

.calendar-column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 0rem;
}

/* Calendar */
.ticketIntroductionImage {
    border-radius: 10px;
    width: 100%;
    height: auto;
    margin: 0rem;
}

.ticket-dayWrapper {
    border: 1px solid rgb(236, 236, 236);
}

.ticket-calendar {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ticket-calendar .calendarHeader {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 0.6em 0;
    color: #f8f8f8;
    text-align: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* border-bottom: 1px solid lightgray; */
}

.ticket-calendar .calendarHeader .icon {
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.ticket-calendar .calendarHeader .icon:hover {
    /* transform: scale(1.1); */
    transition: 0.2s ease-in-out;
    opacity: 0.6;
    /* color: rgb(165, 165, 165); */
}

.ticket-calendar .calendarHeader .icon:first-of-type {
    /* margin-left: 1em; */
}

.ticket-calendar .calendarHeader .icon:last-of-type {
    /* margin-right: 1em; */
}

.monthtext {
    letter-spacing: 0.7px;
}

.yeartext {
    font-weight: 100;
    font-size: 1rem;
}

.ticket-calendar .calendar-days {
    text-transform: lowercase;
    font-weight: 400;
    color: rgb(51, 51, 51);
    font-size: 0.9rem;
    padding: 0.7rem 0.7rem;
    text-align: center;
    /* border-bottom: 1px solid lightgray; */
}

.ticket-calendar .calendar-body .calendar-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* border: 1px solid rgb(0, 0, 0); */
    overflow: hidden;
    background: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
    border-radius: 8px;
    width: 100%;
    height: 3rem;
    /* margin: 0.3125rem; */
    font-weight: 500;
}

.ticket-calendar .calendar-body .calendar-selected {
    border: 4px solid rgb(75, 75, 75);
    font-weight: 700;
}

.ticket-calendar .calendar-body .calendar-cell button:focus-within {
    outline-offset: -3px !important;
    outline: 4px solid black;
}

.ticket-calendar .calendar-body .calendar-row {
    padding: 0.25rem 0.5rem;
    /* border-bottom: 1px solid lightgray; */
}

.ticket-calendar .calendar-body .calendar-row:last-child {
    border-bottom: none;
}

.calendar-price {
    font-weight: 600;
    opacity: 0.8;
    font-size: 0.8rem;
    margin: 0;
    /* margin-top: 0.15rem; */
}

.calendar-cellwrapper {
    display: flex;
    flex-direction: column;
    /* padding-bottom: 0.5em; */
    align-items: center;
    justify-content: center;
}

.ticket-calendar .calendar-body .disabled {
    color: rgb(116, 116, 116);
    pointer-events: none;
}

.calendar-hideDay {
    opacity: 0.1;
}

.ticket-calendar .calendar-body .calendar-cell:hover .bg,
.ticket-calendar .calendar-body .calendar-selected .bg {
    opacity: 0.2;
    transition: 0.2s ease-in-out;
}

.ticket-calendar .calendar-body .calendar-column {
    flex-grow: 0;
    flex-basis: calc(89% / 7);
    width: 100%;
}

div[class*="disabled"] {
    opacity: 0.5 !important;
    pointer-events: none;
}

.calendar-available {
    background-color: rgb(135, 218, 97) !important;
}

.calendar-available:hover {
    opacity: 0.6;
}

.calendar-low:hover {
    opacity: 0.6;
}

.calendar-nocolorallotment:hover {
    opacity: 0.6;
}

.calendar-full {
    pointer-events: none;
    background-color: rgb(207, 79, 79) !important;
    opacity: 0.6;
}

.calendar-low {
    background-color: rgb(224, 222, 87) !important;
}

.calendar-nocolorallotment {
    border: 3px solid lightgrey;
    background-color: transparent !important;
}

.loadingwrapper {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 41.3%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingspinner {
}

.loadingtext {
    color: rgb(53, 53, 53);
    margin-top: 1rem;
    font-weight: 500;
}

.loadingbackground {
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.calendar-timeChooserWrapper {
    margin-top: 5rem;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 768px) {
    .calendar-timeChooserWrapper {
        margin-top: 0rem;
    }
}

.timeChooserWrapper[data-isactive="false"] {
    min-height: 1vh;
}

.timeChooserWrapper[data-isactive="true"] {
    min-height: 45vh;
}

.calendar-timeChooserDate {
    /* margin-top: 4rem; */
    padding-bottom: 1rem;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.changeDateButton {
    color: #3d7adb;
    /* font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif; */
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.changeDateButton:hover {
    color: #7baeff;
}

.calendar-occasionsWrapper {
    display: flex;
    flex-direction: column;
}

.calendar-occasionTimeWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, -webkit-max-content));
    grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
    grid-gap: 10px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    transition: 0.2s ease-in-out;
}

.calendar-occasionHeader {
    font-size: 1rem;
    margin-top: 3rem;
    font-weight: 500;
}

.calendar-occasionTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100px;
    /* height: auto; */
    height: 3.6rem;
    margin: 0 auto;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
}

/* .fadeIn {
  animation: fadeIn 2s linear, slideIn 2s linear;
} */

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes slideIn {
    0% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.calendar-occasionTime:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    opacity: 0.6;
    background: rgb(196, 196, 196);
    transition: 0.2s ease;
}

.calendar-occasionTime .disabled:hover {
    background-color: transparent;
    pointer-events: none;
    cursor: default;
}

.disabled:hover {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: default;
    pointer-events: none;
}

.calendar-occasionTimeText {
    /* margin-top: 0.8rem; */
    margin: 0;
}

.calendar-occasionTime .calendar-price {
    /* margin-top: -1rem; */
}

.calendar-hideScreen {
    opacity: 0.2;
    pointer-events: none;
}

.calendar-hideItems {
    display: none;
}

.calendar-selectedTime {
    box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
}

.timeWrapper {
    transition: 0.2s ease-in-out;
}

.noPointerEvents {
    pointer-events: none;
}

.noPointerEvents:hover {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: default;
}

/* Article Styling */
.calendar-articleChooserWrapper {
    margin-top: 5rem;
}

.calendar-articleChooserWrapper[data-isactive="false"] {
    /* min-height: 45vh; */
}

.calendar-articleChooserWrapper[data-isactive="true"] {
    height: 100%;
}

.calendar-allotmentArticleWrapper {
    /* display: flex;
    flex-direction: column; */
    display: grid;

    margin-top: 3rem;
    margin-bottom: 2rem;
}

.calendar-articleHeader {
    font-weight: 600;
}

.calendar-articleSubHeader {
    /* margin-top: -2.5rem; */
}

.calendar-articleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 130%;
    min-width: 500px;
}

.calendar-articleLeftColumn {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: flex-start;
    padding-right: 2rem;
}

.articleRightColumn {
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    padding-left: 2rem;
}

.removeBorder {
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.calendar-articleButton {
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgb(10, 10, 10);
    border: none;
    background: transparent;
}

.calendar-articleButtonRemove {
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgba(10, 10, 10, 0.651);
    border: none;
    background: transparent;
}

.calendar-articleButton:hover {
    opacity: 0.6;
    cursor: pointer;
}

.calendar-articleButtonRemove:hover {
    opacity: 0.6;
    cursor: pointer;
}

.calendar-articleTitle {
    font-size: 1.2rem;
    font-weight: 500;
}

.calendar-articlePrice {
    font-size: 1rem;
    font-weight: 500;
    margin-top: -1.3rem;
}

.calendar-articleQty {
    min-width: 4rem;
    padding: 0 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.calendar-articleBuyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 2rem;
    transition: all 0.2s ease;
}

.calendar-articleBuyWrapper button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.articleBuyButton {
    font-size: 1rem;
    background-color: rgb(53, 53, 53);
    color: rgb(236, 236, 236);
    font-weight: 500;
    margin: 0 auto;
    width: auto;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    transition: 0.2s ease-in-out;
}

.calendar-articleTotalCost {
    font-size: 1.125rem;
    font-weight: 500;
}

.articleBuyButton:hover {
    opacity: 0.6;
    -webkit-transform: translateY(-1.5px);
    transform: translateY(-1.5px);
    cursor: pointer;
}

.articleOptionWrapper {
    margin-top: 2rem;
}

.articleOptionWrapper h5 {
    font-weight: 500;
}

/* Awesome settings */
@keyframes rainbow {
    from {
        background-position: -100vh 0;
    }
    to {
        background-position: 100vh 0;
    }
}

@-webkit-keyframes rainbow {
    from {
        background-position: -100vh 0;
    }
    to {
        background-position: 100vh 0;
    }
}

@media screen and (max-width: 768px) {
    .few-left-indicator {
        margin: auto;
        left: .5rem !important;
        top: .25rem !important;
    }
    .few-left-indicator-day {
        margin: auto;
        left: 0.15rem !important;
        top: -0.25rem !important;
        width: .9rem !important;
        height: .9rem !important;
        /* right: 0 !important; */
    }
    .ticketIntroductionImage {
        width: 100%;
    }

    .legend-chosen {
        width: 30px;
        height: 28px;
    }

    .legend-available {
        width: 30px;
        height: 28px;
    }

    .legend-low {
        width: 30px;
        height: 28px;
    }

    .legend-full {
        width: 30px;
        height: 28px;
    }

    .calendar-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ticket-calendar .calendar-body .calendar-cell {
        margin-top: 0.5rem;
        flex-basis: calc(100% / 7) !important;
    }

    .ticket-calendar {
        /* max-width: 85vw; */
    }

    .ticket-calendar .calendar-body .calendar-row {
        height: calc(26vw / 1.72);
    }

    .calendar-price {
        display: none;
    }

    .ticket-calendar .calendar-body .calendar-cell {
        /* border-radius: 50%; */
        width: 100%;
        height: auto;
        padding: 0;
    }

    .calendar-number {
        font-size: 0.85rem !important;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 !important;
    }

    .calendar-cellwrapper {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 2rem !important;
    }

    .ticket-calendar .calendar-body .calendar-selected {
        border: 0px solid rgb(75, 75, 75);
        font-weight: 700;
        box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    }

    .calendar-row {
        margin: 0;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .loadingwrapper {
        width: 100%;
        left: 0 !important;
        right: 0 !important;
    }

    .calendar-occasionTimeWrapper {
        max-width: 90vw;
        justify-content: center;
    }

    .calendar-articleWrapper {
        border-bottom: 1px solid lightgrey;
        align-items: flex-end;
        margin-bottom: 0rem;
        padding-bottom: 0.8rem;
        align-items: flex-start;
        width: auto;
        min-width: auto;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .calendar-articleLeftColumn {
        padding: 0;
    }

    .calendar-articlePrice {
        margin-bottom: -0.1rem;
        font-size: 0.95rem;
        font-weight: 600;
    }

    .calendar-articleTitle {
        font-size: 1.125rem;
        text-align: left;
        margin-bottom: 1.25rem;
        font-weight: 500;
    }

    .calendar-articleRightColumnToggle {
        min-width: 150px;
    }
}

.newButton {
    position: relative;
    width: 150px;
    padding-bottom: 5rem;
}

.newButtonWrapper {
    position: absolute;
    width: 150px;
    height: 35px;
    background-color: rgb(148, 148, 148);

    border-radius: 35px;
}

.calendar-articleButton2 {
    position: relative;
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgb(53, 53, 53);
    -webkit-transform: translate(200%, -7%);
    transform: translate(200%, -7%);
    z-index: 100;
}

.calendar-articleButton2:hover {
    opacity: 0.6;
    cursor: pointer;
}

.calendar-articleButton21 {
    position: relative;
    height: 40px;
    width: 40px;
    transition: 0.2s ease-in-out;
    color: rgb(53, 53, 53);
    margin: 0 0rem;
    -webkit-transform: translate(50%, -7%);
    transform: translate(50%, -7%);
    opacity: 0;
    z-index: 50;
}

.calendar-articleButton21:hover {
    /* opacity: 0.6; */
    cursor: pointer;
}

.moveButton {
    opacity: 1;
    transition: all 0.5s ease;
    -webkit-transform: translate(-200%, -7%);
    transform: translate(-200%, -7%);
}

.addButtonWrapper {
    transition: all 0.2s ease;
}

.addButton:hover {
    transition: all 0.2s ease;
    opacity: 0.6;
}

.addButton {
    width: 50px;
    height: 50px;
}

.cartButton:hover {
    transition: all 0.2s ease;
    opacity: 0.6;
}

.cartButton {
    width: 50px;
    height: 50px;
}

.addedButton {
    -webkit-animation: fadeIn 1.5s ease;
            animation: fadeIn 1.5s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        width: 257px;
    }
    30% {
        width: 92px;
    }
    50% {
        opacity: 1;
        width: 92px;
    }
    100% {
        width: 92px;
    }
}

/* ToggleButton settings */
.calendar-articleRightColumnToggle {
    position: relative;
    height: auto;
    margin-top: 1rem;
    min-width: 150px;
}

.calendar-switch {
    width: 40px;
    height: 40px;
    background-color: rgba(206, 206, 206, 0.4);
    position: absolute;
    left: 70%;

    border-radius: 50px;
    cursor: pointer;
}

.calendar-switch[data-ison="true"] {
    position: absolute;
    left: 0%;
    width: 140px;
    height: 40px;
}

.calendar-handle {
    position: absolute;
    top: 0%;
    left: 70%;
    z-index: 2;
    width: 40px;
    height: 40px;
    background-color: rgb(219, 219, 219);
    border-radius: 40px;
}

/* .calendar-handle[data-ison="true"] {
  left: 70%;
} */

.calendar-handle2 {
    position: absolute;
    top: 0%;
    left: 70%;
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: rgb(219, 219, 219);
    border-radius: 40px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.calendar-handle2[data-ison="true"] {
    left: 0%;
}

.calendar-count {
    position: absolute;
    top: 0%;
    left: 0%;
    margin: 0 auto;
    /* font-family: "Arial"; */
    color: rgb(53, 53, 53);
    font-size: 1.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0;
    text-align: center;
}

.calendar-count[data-ison="true"] {
    top: 0%;
    left: 27%;
    opacity: 1;
}


.wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1500;
    -webkit-backdrop-filter: blur(3px) brightness(50%);
            backdrop-filter: blur(3px) brightness(50%);
    padding-bottom: 10rem;
    width: 100%;
    height: 100%;
}

.ReactModal__Overlay {
    display: flex;
    align-items: center;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.moveoccasions-modal {
    height: auto;
    width: 100%;
    background-color: rgb(250, 250, 250, 0);
    border-radius: 10px;
    overflow: hidden;
    border: none;
    outline: none;
}

.moveoccasions-modalContent {
    max-height: 100vh;
    overflow: auto;
    padding: 0 !important;
    max-width: 500px;
    width: 100%;
    background-color: rgba(250, 250, 250, 1) !important;
    border-radius: 10px !important;
    margin: 0 auto;
    border: none !important;
    outline: none;
}

.topHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #313a2f;
    width: 100%;
    height: 100%;
    color: rgb(247, 247, 247);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 0.65rem;
    padding-bottom: 1.6rem;
}

.topHeader h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2.3rem;

}

.previousPageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 0.8rem;
    margin-bottom: -1.6rem;
    transition: all 0.2s ease;
    max-height: 56px;
}

.previousBtnWrapper {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    max-height: 56px;
    align-items: center;
}

.previousBtnWrapper:hover {
    opacity: 0.7;
}

.previousPageBtn {
    text-align: center;
    font-weight: 600;
    opacity: 0.7;
    font-size: 1rem;
}

.previousPageIcon {
    width: 1.25rem;
    opacity: 0.6;
}

.progressWrapper {
    position: relative;
    margin-top: 1.5rem;
    width: 85%;
    height: auto;
}

.icon {
    color: #f7f7f7;
}

.progressLine {
    position: absolute;
    width: 100%;
    background-color: rgb(247, 247, 247);
    border: solid 2px rgb(247, 247, 247);
    border-radius: 5px;
}

.progressCircleWrapper {
    position: relative;
    width: 100%;
    top: -10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.progressCircleWrapper p {
    font-weight: 600;
}

.progressCircleOutline {
    z-index: 10;
    background: #313a2f;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: solid 2px white;
}

.progressCircle {
    background: rgb(247, 247, 247);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.content {
    height: auto;
    position: relative;
    margin: 0 auto;
    max-width: 90%;
    /* right: -5px; */
}

.content h2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}

.removeBorderBg {
    background: transparent;
}


.content h3,
label {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}

.occasionWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.notAvailable {
    border: solid 1px black;
    opacity: 0.5;
    pointer-events: none;
}

.oldOccasion {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: center;
    width: 100%;
    background-color: #fff;
    border: solid 1.5px rgb(75, 75, 75);
    border-radius: 10px;
    height: 3.5rem;
    margin: 1rem 0rem;
}

.oldOccasion .content,
.newOccasionTime .content {
    font-weight: 600;
}

.oldOccasion:hover {
    background-color: #e3e3e3;
    cursor: pointer;
}

.newOccasionTime {
    display: flex;
    align-items: center;
    height: 3.5rem;
    font-weight: 500;
    width: auto;
    background-color: #fff;
    border: solid 1.5px rgb(75, 75, 75);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 0.5rem 0.7rem;
}

.newOccasionTime:hover {
    background-color: #e3e3e3;
    cursor: pointer;
}

.available {
    border: solid 1px black;
}

.chosen {
    background-color: #313a2f;
    color: #fff;
    font-weight: 600;
    border: none;
}

.chosen:hover {
    background-color: #313a2f;
}

.pinWrapper {
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-top: 0.9rem;
}

.pinWrapper p {
    margin-top: -0.5rem;
    text-align: center;
    font-weight: 400;
}

.pinWrapper input {
    letter-spacing: 1rem;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.pinWrapper input[type="text"] {
    padding-right: 1rem !important;
}

.pin {
    height: 3.5rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 0;
}

/* Input box settings */
::-webkit-input-placeholder {
    color: rgb(180, 180, 180);
}

:-ms-input-placeholder {
    color: rgb(180, 180, 180);
}

::placeholder {
    color: rgb(180, 180, 180);
}

.errorMsg {
    display: flex;
    align-items: center;
    background-color: #f24b4b;
    /* min-height: 3rem; */
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
}

.errorMsg p {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.contentFooter {
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

@media screen and (max-width: 768px) {
    .content h2 {
        padding-top: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        top: 0px;
    }

    .moveoccasions-modal {
        height: auto;

    }

    .contentFooter {
        grid-gap: 0rem;
        padding-top: 3rem;
        display: flex;
        height: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .contentFooter div button {
        min-width: 100%;
    }

    .contentFooter div div {
        min-width: 100%;
    }

    .content {
        /* max-width: 80vw; */
        height: auto;
        /* padding: 1rem 0; */
        right: 0;
    }

    .ReactModal__Overlay {
        z-index: 1500;
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-backdrop-filter: blur(3px);
                backdrop-filter: blur(3px);
        padding-top: 1rem;
    }

    .ReactModal__Content {
        inset: 0px !important;
        width: 95% !important;
        background-color: rgb(250, 250, 250, 1) !important;
        border-radius: 10px !important;
        margin: 0 auto !important;
        margin-top: 0.5rem !important;
        border: none !important;
        /* padding-bottom: 0.5rem !important; */
    }

    .newOccasionTime {
        height: 3.5rem;
    }
}

/*
@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 20px;
    z-index: 1600;
  }
  
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
} */

.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.5rem;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid lightgray;
}

.progressItemWrapper {
  margin: 0 auto;
  height: 100%;
  align-self: stretch;
}

.progressTitle {
  margin-bottom: 0.35rem;
}

.progressItem {
  padding: 0rem 0.55rem;
  height: auto;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  opacity: 0.2;
  width: 30px;
  margin-top: 0.7rem;
}

.disabledTitle {
  opacity: 0.2;
}

.activeProgress {
  width: 45px;
  height: auto;
  margin: 0 auto;
  padding: 0.4rem 1rem;
  border-radius: 50%;
  background-color: rgb(56, 56, 56);
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  align-self: center;
}

.bgBlur {
  -webkit-filter: blur(3.5px);
          filter: blur(3.5px);
  pointer-events: none;
}

.spinnerWrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 25%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 1rem 0rem;
  color: #fff;
  background-color: rgb(46, 46, 46);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.subHeader {
  font-size: 1.25rem;
}

.subHeader:first-letter {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

/* Calendar */
.calendar {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid rgb(236, 236, 236);
  height: auto;
  margin-top: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.calendar .calendarHeader {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.6em 0;
  color: #f8f8f8;
  text-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.calendar .calendarHeader .icon {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.icon:hover {
  transition: 0.2s ease-in-out;
  opacity: 0.6;
  cursor: pointer;
}

.monthtext {
  letter-spacing: 0.7px;
}

.yeartext {
  font-weight: 100;
  font-size: 1rem;
}

.calendar .days {
  text-transform: lowercase;
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-size: 0.9rem;
  padding: 0.7rem 0.7rem;
  text-align: center;
}

.calendar .body .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid rgb(0, 0, 0);
  overflow: hidden;
  cursor: pointer;
  /* background: rgb(255, 255, 255); */
  transition: 0.2s ease-in-out;
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  margin: 0.3125rem;
  font-weight: 500;
}

.calendar .body .disabled {
  border: none;
  cursor: default;
  pointer-events: none;
  color: lightgrey;
}

.calendar .body .cell:hover {
  background: rgb(196, 196, 196);
  transition: 0.2s ease-in-out;
}

.calendar .body .selected {
  background: #313a2f;
  color: #fff;
  font-weight: 600;
}

.calendar .body .selected:hover {
  background: #313a2f;
  color: #fff;
  font-weight: 600;
}

.calendar .body .row {
  padding: 0.5rem;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.price {
  font-weight: 600;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-top: 0.15rem;
}

.cellwrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.2;
  transition: 0.2s ease-in-out;
}

.calendar .body .column {
  flex-grow: 0;
  flex-basis: calc(92% / 7);
  width: calc(100% / 7);
}

div[class*="disabled"] {
  opacity: 0.2 !important;
  pointer-events: none;
  border: none !important;
}

.loadingwrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 41.3%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .calendar .body .row {
    height: calc(26vw / 1.72);
  }

  .price {
    display: none;
  }

  .calendar .body .cell {
    border-radius: 50%;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .number {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .cellwrapper {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .calendar .body .selected {
    /* border: 0px solid rgb(75, 75, 75); */
    font-weight: 700;
    /* -webkit-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    -moz-box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75);
    box-shadow: inset 0px 0px 0px 3px rgb(75, 75, 75); */
  }
}

.hideContent {
  opacity: 0;
  /* transition: all 2s ease-in-out; */
  -webkit-animation: hideContentAnimation 0.2s;
          animation: hideContentAnimation 0.2s;
}

.showContent {
  opacity: 1;
  /* transition: all 4s ease-in-out; */
  -webkit-animation: showContentAnimation 0.1s;
          animation: showContentAnimation 0.1s;
}

@-webkit-keyframes hideContentAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hideContentAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes showContentAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showContentAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.introductionImage {
  object-fit: cover;
}

.articleHeaderWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3rem 0rem;
  border-radius: 10px;
}

.articleHeaderWrapper div h1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.articleHeader {
  text-align: center;
}

.articleHeader div div h4 {
  font-size: 1.8rem;
  font-weight: 600;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* gap: 1rem; */

  padding-top: 1rem;
}

.buttonWrapper > * {
  margin: 0 0.5rem;
}

.changeArticleButton {
  padding: 0.7rem 1rem;
}

.continueHeader {
  font-size: 1.5rem;
  margin-top: 3rem;
  margin-bottom: -3rem;
  color: black;
}

.introductionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  transition: all 1s ease-in-out;
}

.introductionHeader div h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

.introductionTextWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem 0 1rem 0;
  width: 100%;
}

.introductionImage {
  width: 50%;
  height: 300px;
  border-radius: 10px;
  background-size: cover;
}

.introductionText {
  width: 50%;
}

/* .introductionText p {
  margin: 0;
  padding: 0;
} */

.readMoreButton {
  font-weight: 600;
  display: none;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background: none;
  border: none;
  padding: 0;
  font-size: 1rem;
}

.articleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  font-size: 130%;
  width: 100%;
  align-content: space-between;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.articleWrapper:nth-of-type(2) {
  margin-top: -3rem;
  margin-bottom: -1rem;
}

.articleBookingWrapper {
  scroll-margin: 2rem;
}

.activeArticleButton:focus,
.activeArticleButton:active {
  border: none !important;
  outline: none !important;
}

.articleModalButton:focus {
  outline: 2px solid black;
  opacity: 0.7;
}

.article {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 600px;
  width: 30rem;
}

.articleLeftColumn {
  display: flex;
  flex-direction: column;
  height: auto;

  align-items: flex-start;
  padding-right: 2rem;
}

.articleTitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.articlePrice {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0rem;
}

.articleQty {
  font-size: 1.2rem;
  font-weight: 500;
}

/* ToggleButton settings */
.articleRightColumnToggle {
  position: relative;
  height: auto;
  min-width: 130px;
}

.removeBorderBg {
  border: none;
  background: transparent;
}

.articleButton {
  height: 40px;
  width: 40px;
  transition: 0.2s ease-in-out;
  border: none;
  background: transparent;
}

.articleButtonRemove {
  height: 40px;
  width: 40px;
  transition: 0.2s ease-in-out;
}

.articleButton:hover {
  opacity: 0.6;
  cursor: pointer;
}

.articleButtonRemove:hover {
  opacity: 0.6;
  cursor: pointer;
}

.switch {
  width: 40px;
  height: 40px;
  background-color: rgba(206, 206, 206, 0.4);
  position: absolute;
  left: 70%;
  border-radius: 50px;
  cursor: pointer;
}

.switch[data-ison="true"] {
  position: absolute;
  left: 0%;
  width: 130px;
  height: 40px;
}

.handle {
  position: absolute;
  top: 0%;
  left: 70%;
  z-index: 2;
  width: 40px;
  height: 40px;
  background-color: rgb(219, 219, 219);
  border-radius: 40px;
}

.handle2 {
  position: absolute;
  top: 0%;
  left: 70%;
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: rgb(219, 219, 219);
  border-radius: 40px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.buttonIcons {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hideScreen {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.handle2[data-ison="true"] {
  left: 0%;
}

.count {
  position: absolute;
  top: 0%;
  left: 90%;
  margin: 0 auto;
  font-family: "Arial";
  color: rgb(53, 53, 53);
  font-size: 1.5rem;
  font-weight: bold;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0;
}

.count[data-ison="true"] {
  top: 1.5%;
  /* left: 48%; */
  left: 0%;
  right: 0%;
  opacity: 1;
}

.count[data-over9="true"] {
  top: 1.5%;
  /* left: 40%; */
  opacity: 1;
}

.count[data-over99="true"] {
  top: 1.5%;
  /* left: 35%; */
  opacity: 1;
}

.articleTotalCost {
  font-size: 1.125rem;
  font-weight: 600;
}

.articleBookingCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.articleBookingCartButtonClicked {
  pointer-events: none;
}

.cartIcon {
  display: flex;
}

/* Modal styling */
.ReactModal__Overlay {
  display: flex;
  align-items: center;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.ReactModal__Content {
  max-width: 500px;
  display: flex;
  margin: 0 auto;
  inset: none;
  position: relative !important;
  border-radius: 10px !important;
}

.modal {
  height: auto;
  background-color: rgb(250, 250, 250, 0);
  border-radius: 10px;
  overflow: auto;
  border: none;
  outline: none;
  padding: 2.5rem;
}

.modalContent {
  max-height: 100vh;
  overflow: auto;
  padding: 0 !important;
  max-width: 500px;
  width: 100%;
  background-color: rgba(250, 250, 250, 1) !important;
  border-radius: 10px !important;
  margin: 0 auto;
  border: none !important;
  outline: none;
}

.modalContent h2 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem !important;
}

.modalBtnWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.animationWrapper {
  margin: 1.7rem 0;
}

.articleAddToCartButton {
  background: none;
}

@media screen and (max-width: 768px) {
  .readMoreButton {
    display: block;
  }

  .articleWrapper {
    min-width: 0%;
    padding: 3rem 0rem;
  }

  .articleWrapper:nth-of-type(2) {
    margin-top: -5rem;
    margin-bottom: -4.5rem;
  }

  .article {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    width: 100%;
    border-bottom: 1px solid lightgrey;
    height: 100%;
    margin-bottom: 1rem;
  }

  .articleLeftColumn {
    padding-right: 0rem;
  }

  .articleRightColumnToggle {
    min-height: 60px;
    max-width: 150px;
    justify-self: end;
  }

  .handle {
    left: calc(100% - 2.3rem);
  }

  .handle2 {
    left: calc(100% - 2.3rem);
  }

  .handle2[data-ison="true"] {
    left: 0%;
  }

  .switch {
    left: calc(100% - 2.3rem);
  }

  .switch[data-ison="true"] {
    position: absolute;
    width: 100%;
    height: 40px;
  }

  .count {
    left: 100%;
  }

  .count[data-ison="true"] {
    /* top: 1.5%; */
    -webkit-transform: translateX(50%, -50%);
    transform: translateX(50%, -50%);
    opacity: 1;
    left: 0%;
    right: 0%;
  }

  .articleTitle {
    text-align: left;
  }

  .articleTotalCost {
    padding-top: 2rem;
  }

  .buttonWrapper {
    flex-direction: column;
  }

  .buttonWrapper > * {
    margin: 0 0 1rem 0;
  }

  .introductionTextWrapper {
    flex-direction: column;
  }

  .introductionImage {
    width: 100%;
  }

  .introductionText {
    width: 100%;
  }

  .articleHeaderWrapper {
    /* padding: 2.5rem 1rem 3rem 1rem; */
  }

  .articleRightColumnToggle {
    width: auto;
  }

  .ReactModal__Overlay {
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
  }

  .ReactModal__Content {
    width: 95% !important;
    background-color: rgb(250, 250, 250, 1) !important;
    border-radius: 10px !important;
    margin: 0 auto !important;
    border: none !important;
  }

  .modalContent {
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
  }

  .modal {
    overflow-y: auto;
  }

  .scroller {
    scroll-margin: 2rem;
  }

  .modalBtnWrapper {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 330px) {
    .articleRightColumnToggle {
      max-width: 100%;
      width: 100%;
      justify-self: center;
    }
  }
}

.mantine-Calendar-day[data-selected="true"] {
    background-color: var(--theme-primary-color) !important;
    color: var(--theme-primary-color-text) !important;
}

.primaryButton {
    background-color: var(--theme-primary-color);
    color: var(--theme-primary-color-text);
}

.primaryButtonOutline {
    border-color: var(--theme-primary-color);
    color: var(--theme-primary-color);
}

.primaryButton:hover {
    background-color: var(--theme-primary-hover-color);
}

.mantine-Input-rightSection {
    height: 2.5rem;
}

.mantine-Checkbox-input[type="checkbox"] {
    display: block;
}

.mantine-Checkbox-input, .mantine-Input-input {
    /*border: 1px solid #8A8C93;*/
}

.mantine-TextInput-label, .mantine-Checkbox-label, .mantine-NumberInput-label {
    font-size: 0.9rem;
}

.mantine-Stepper-stepIcon[data-completed="true"], .mantine-Stepper-separatorActive {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.mantine-Calendar-monthPickerControlActive {
    background-color: var(--theme-primary-color);
    color: var(--theme-primary-color-text);
}

.mantine-Calendar-monthPickerControlActive:hover {
    background-color: var(--theme-primary-hover-color);
}

.mantine-Stepper-stepWrapper div[data-progress="true"] {
    border-color: var(--theme-primary-color);
}

.pbCalendar div table tbody tr td {
    text-align: center !important;
    padding: 0.35rem;
}

.pbCalendar div table tbody tr td button {
    width: 100%;
}

.pbCalendar div table tbody tr td button:not(:disabled) {
    border: 1px solid var(--theme-primary-color);
}

.mantine-NumberInput-description {
    color: black;
}

.mantine-NumberInput-control {
    padding: 0 0.2rem 0 0.2rem !important;
}

.mantine-Checkbox-label, .mantine-Radio-label {
    font-size: .9rem;
    margin: 0;
    padding: 0 0 0 0.5rem;
}

.mantine-Checkbox-input:checked, .mantine-Radio-root[data-checked="true"] div div input {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}


@media screen and (max-width: 768px) {
    .mantine-Stepper-separator {
        margin-left: 0.26rem;
        margin-right: 0.26rem;
    }
}

.publicBookingModal .mantine-Modal-title {
    font-weight: 500;
}
